import { initializeApp } from "firebase/app"
import {
  getAuth,
  FacebookAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth"

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
}

let appInstance = null
let authInstance = null
let facebookAuthProviderInstance = null
let googleAuthProviderInstance = null

export const getFirebase = () => {
  if (typeof window !== "undefined") {
    if (appInstance) return appInstance
    appInstance = initializeApp(firebaseConfig)
    return appInstance
  }
  return null
}

export const getAuthApp = () => {
  if (typeof window !== "undefined") {
    if (!authInstance) {
      const app = getFirebase() // Ensure Firebase is initialized
      authInstance = getAuth(app)
    }
    return authInstance
  }
  return null
}

export const getFacebookAuthProvider = () => {
  if (typeof window !== "undefined") {
    if (!facebookAuthProviderInstance) {
      facebookAuthProviderInstance = new FacebookAuthProvider()
    }
    return facebookAuthProviderInstance
  }
  return null
}

export const getGoogleAuthProvider = () => {
  if (typeof window !== "undefined") {
    if (!googleAuthProviderInstance) {
      googleAuthProviderInstance = new GoogleAuthProvider()
    }
    return googleAuthProviderInstance
  }
  return null
}
